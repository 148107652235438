var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "liveinfo-form", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { cols: "12" },
                  },
                  [
                    _c("v-select", {
                      staticClass: "el-def",
                      staticStyle: { width: "200px" },
                      attrs: {
                        options: _vm.codes.clients,
                        placeholder: "제약사",
                        reduce: function (option) {
                          return option.code
                        },
                      },
                      model: {
                        value: _vm.query.clientId,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "clientId", $$v)
                        },
                        expression: "query.clientId",
                      },
                    }),
                    _c("v-select", {
                      staticClass: "invoice-filter-select el-def",
                      attrs: {
                        options: _vm.codes.postStatusType,
                        reduce: function (option) {
                          return option.code
                        },
                        placeholder: "게시상태",
                      },
                      model: {
                        value: _vm.query.liveType,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "liveType", $$v)
                        },
                        expression: "query.liveType",
                      },
                    }),
                    _c("b-form-input", {
                      staticClass: "el-def",
                      attrs: { placeholder: "제목" },
                      model: {
                        value: _vm.query.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "title", $$v)
                        },
                        expression: "query.title",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-1" },
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { cols: "12", md: "10" },
                  },
                  [
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: {
                        id: "datepicker-placeholder1",
                        placeholder: "게시기간(Fr)",
                      },
                      model: {
                        value: _vm.query.startDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "startDate", $$v)
                        },
                        expression: "query.startDate",
                      },
                    }),
                    _c("label", { staticClass: "ml-25 mr-50" }, [_vm._v("~")]),
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: {
                        id: "datepicker-placeholder2",
                        placeholder: "게시기간(To)",
                      },
                      model: {
                        value: _vm.query.endDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "endDate", $$v)
                        },
                        expression: "query.endDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                    attrs: { cols: "12", md: "2" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-search",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.refetchData()
                          },
                        },
                      },
                      [_vm._v(" 조회 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("h5", { staticClass: "mb-0 ml-50" }, [
                        _vm._v(
                          " 간편처방등록 리스트 [" +
                            _vm._s(_vm.totalRecords) +
                            "] 건 "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "primary-key": "liveInfoId",
              items: _vm.drugLandingList,
              fields: _vm.tableColumns,
              responsive: "",
              "sticky-header": "",
              selectable: "",
              "select-mode": "single",
            },
            on: { "row-selected": _vm.onRowSelectedDrugLanding },
            scopedSlots: _vm._u([
              {
                key: "cell(liveInfoId)",
                fn: function (data) {
                  return [_vm._v(" " + _vm._s(data.value) + " ")]
                },
              },
              {
                key: "cell(title)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(data.value) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-pagination", {
            attrs: {
              "total-rows": _vm.totalRecords,
              limit: "6",
              "per-page": "6",
              align: "center",
            },
            model: {
              value: _vm.currPage,
              callback: function ($$v) {
                _vm.currPage = $$v
              },
              expression: "currPage",
            },
          }),
        ],
        1
      ),
      _c(
        "validation-observer",
        { ref: "formRules" },
        [
          _c("b-card", { attrs: { "no-body": "" } }, [
            _c(
              "div",
              { staticClass: "m-2" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-start",
                        attrs: { cols: "12", md: "6" },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "EditIcon", size: "19" },
                        }),
                        _c("h4", { staticClass: "mb-0 ml-50" }, [
                          _vm._v(" 상세 "),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-end",
                        attrs: { cols: "12", md: "6" },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-primary" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.resetDrugLandingData()
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-50",
                              attrs: { icon: "PlusSquareIcon" },
                            }),
                            _c("span", [_vm._v("신규 등록")]),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-1",
                            attrs: {
                              disabled: _vm.isProcessing,
                              variant: "primary",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.saveDrugLanding()
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-50",
                              attrs: { icon: "SaveIcon" },
                            }),
                            _c("span", [_vm._v("저장")]),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-1",
                            attrs: {
                              disabled:
                                _vm.drugLandingData.liveInfo.liveTypeStr !==
                                "게시 예정",
                              variant: "outline-secondary",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.removeDrugLanding()
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-50",
                              attrs: { icon: "Trash2Icon" },
                            }),
                            _c("span", [_vm._v("삭제")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  { attrs: { rules: "required" } },
                  [
                    _c(
                      "b-row",
                      { staticClass: "mt-1" },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "10" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "제목",
                                  "label-for": "liveinfo-title",
                                },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "제목", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "title",
                                              placeholder: "입력하세요",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.drugLandingData.liveInfo
                                                  .title,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.drugLandingData.liveInfo,
                                                  "title",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "drugLandingData.liveInfo.title",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "10" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "제품명",
                                  "label-for": "liveinfo-drug-name",
                                },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "제품명", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "liveinfo-drug-name",
                                              placeholder: "입력하세요",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.drugLandingData.drugName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.drugLandingData,
                                                  "drugName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "drugLandingData.drugName",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "10", md: "5" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "제약사",
                                  "label-for": "druglanding-client-id",
                                },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "제약사", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              id: "druglanding-client-id",
                                              options: _vm.codes.clients,
                                              placeholder: "선택하세요",
                                              reduce: function (option) {
                                                return option.code
                                              },
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.drugLandingData.clientId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.drugLandingData,
                                                  "clientId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "drugLandingData.clientId",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "10", md: "5" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "청구코드",
                                  "label-for": "druglanding-search-keyword",
                                },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "청구코드",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "condDrugCodes",
                                              disabled:
                                                _vm.drugLandingData.liveInfo
                                                  .liveTypeStr !==
                                                  "게시 예정" &&
                                                _vm.drugLandingData
                                                  .liveInfoId !== null,
                                              placeholder:
                                                "청구코드를 입력하세요",
                                            },
                                            model: {
                                              value:
                                                _vm.drugLandingData.liveInfo
                                                  .condDrugCodes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.drugLandingData.liveInfo,
                                                  "condDrugCodes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "drugLandingData.liveInfo.condDrugCodes",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { cols: "10", md: "10" } }, [
                          _c("label", [_vm._v("게시기간")]),
                        ]),
                        false
                          ? _c("b-col", { attrs: { cols: "10", md: "5" } }, [
                              _c("label", [_vm._v("연령")]),
                            ])
                          : _vm._e(),
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-start",
                            attrs: { cols: "10", md: "10" },
                          },
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "flex-grow-1" },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "게시기간(Fr)",
                                    rules: {
                                      required: true,
                                      before:
                                        _vm.drugLandingData.liveInfo.endDate,
                                    },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-datepicker", {
                                            attrs: {
                                              id: "liveinfo-start-date",
                                              placeholder: "게시기간(Fr)",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.changeStartDate()
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.drugLandingData.liveInfo
                                                  .startDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.drugLandingData.liveInfo,
                                                  "startDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "drugLandingData.liveInfo.startDate",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c("b-form-group", [
                              _c("label", { staticClass: "ml-50 mr-50" }, [
                                _vm._v("~"),
                              ]),
                            ]),
                            _c(
                              "b-form-group",
                              { staticClass: "flex-grow-1" },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "게시기간(To)",
                                    rules: {
                                      required: true,
                                      after:
                                        _vm.drugLandingData.liveInfo.startDate,
                                    },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-datepicker", {
                                            attrs: {
                                              id: "liveinfo-end-date",
                                              placeholder: "게시기간(To)",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.drugLandingData.liveInfo
                                                  .endDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.drugLandingData.liveInfo,
                                                  "endDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "drugLandingData.liveInfo.endDate",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        false
                          ? _c(
                              "b-col",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-start",
                                attrs: { cols: "10", md: "5" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  { staticClass: "flex-grow-1" },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "시작나이",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-input", {
                                                  on: {
                                                    keyup:
                                                      _vm.restrictToNumbers,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.drugLandingData
                                                        .liveInfo.minAge,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.drugLandingData
                                                          .liveInfo,
                                                        "minAge",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "drugLandingData.liveInfo.minAge",
                                                  },
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1223104931
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c("b-form-group", [
                                  _c("label", { staticClass: "ml-50 mr-50" }, [
                                    _vm._v("~"),
                                  ]),
                                ]),
                                _c(
                                  "b-form-group",
                                  { staticClass: "flex-grow-1" },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "끝나이",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-input", {
                                                  on: {
                                                    keyup:
                                                      _vm.restrictToNumbers,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.drugLandingData
                                                        .liveInfo.maxAge,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.drugLandingData
                                                          .liveInfo,
                                                        "maxAge",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "drugLandingData.liveInfo.maxAge",
                                                  },
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        966675645
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "10" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "진료과",
                                  "label-for": "liveinfo-cond-dept",
                                },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "진료과", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "liveinfo-cond-dept",
                                              readonly: "",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.drugLandingData.liveInfo
                                                  .condDeptNames,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.drugLandingData.liveInfo,
                                                  "condDeptNames",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "drugLandingData.liveInfo.condDeptNames",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "mb-50", attrs: { cols: "2" } },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mt-0 mt-md-2",
                                attrs: { variant: "outline-primary" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.showMediDeptSelect = true
                                  },
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "mr-50",
                                  attrs: { icon: "PlusSquareIcon" },
                                }),
                                _c("span", [_vm._v("목록")]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "10" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "전공과",
                                  "label-for": "liveinfo-cond-major",
                                },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "전공과", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "liveinfo-cond-major",
                                              readonly: "",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.drugLandingData.liveInfo
                                                  .condMajorNames,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.drugLandingData.liveInfo,
                                                  "condMajorNames",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "drugLandingData.liveInfo.condMajorNames",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "mb-50", attrs: { cols: "2" } },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mt-0 mt-md-2",
                                attrs: { variant: "outline-primary" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.showMajorSelect = true
                                  },
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "mr-50",
                                  attrs: { icon: "PlusSquareIcon" },
                                }),
                                _c("span", [_vm._v("목록")]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "10" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "지역",
                                  "label-for": "liveinfo-cond-region",
                                },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "지역", rules: "required" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "liveinfo-cond-region",
                                              readonly: "",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.drugLandingData.liveInfo
                                                  .condRegionNames,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.drugLandingData.liveInfo,
                                                  "condRegionNames",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "drugLandingData.liveInfo.condRegionNames",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "mb-50", attrs: { cols: "2" } },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mt-0 mt-md-2",
                                attrs: { variant: "outline-primary" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.showRegionSelect = true
                                  },
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "mr-50",
                                  attrs: { icon: "PlusSquareIcon" },
                                }),
                                _c("span", [_vm._v("목록")]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("major-select", {
        attrs: {
          "show-major-select": _vm.showMajorSelect,
          "selected-value": _vm.drugLandingData.liveInfo.condMajorCodes,
        },
        on: { close: _vm.majorSelect.close },
      }),
      _c("region-select", {
        attrs: {
          "show-region-select": _vm.showRegionSelect,
          "selected-value": _vm.drugLandingData.liveInfo.condRegionCodes,
        },
        on: { close: _vm.regionSelect.close },
      }),
      _c("medi-dept-select", {
        attrs: {
          "show-medi-dept-select": _vm.showMediDeptSelect,
          "selected-value": _vm.drugLandingData.liveInfo.condDeptCodes,
        },
        on: { close: _vm.mediDeptSelect.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }