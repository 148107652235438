<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="query.clientId"
              :options="codes.clients"
              placeholder="제약사"
              :reduce="option => option.code"
              class="el-def"
              style="width:200px;"
            />

            <v-select
              v-model="query.liveType"
              :options="codes.postStatusType"
              class="invoice-filter-select el-def"
              :reduce="(option) => option.code"
              placeholder="게시상태"
            />

            <b-form-input
              v-model="query.title"
              class="el-def"
              placeholder="제목"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-datepicker
              id="datepicker-placeholder1"
              v-model="query.startDate"
              class="el-def"
              placeholder="게시기간(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              id="datepicker-placeholder2"
              v-model="query.endDate"
              class="el-def"
              placeholder="게시기간(To)"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              간편처방등록 리스트 [{{ totalRecords }}] 건
            </h5>
          </b-col>
        </b-row>
      </div>
      <b-table
        primary-key="liveInfoId"
        :items="drugLandingList"
        :fields="tableColumns"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedDrugLanding"
      >
        <!-- Column: No -->
        <template #cell(liveInfoId)="data">
          {{ data.value }}
        </template>
        <template #cell(title)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>

      </b-table>
      <b-pagination
        v-model="currPage"
        :total-rows="totalRecords"
        limit="6"
        per-page="6"
        align="center"
      />
    </b-card>

    <validation-observer ref="formRules">
      <b-card no-body>
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start"
            >
              <feather-icon
                icon="EditIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                상세
              </h4>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="outline-primary"
                @click.prevent="resetDrugLandingData()"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  class="mr-50"
                />
                <span>신규 등록</span>
              </b-button>
              <b-button
                :disabled="isProcessing"
                variant="primary"
                class="ml-1"
                @click.prevent="saveDrugLanding()"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span>저장</span>
              </b-button>
              <b-button
                :disabled="drugLandingData.liveInfo.liveTypeStr !== '게시 예정'"
                variant="outline-secondary"
                class="ml-1"
                @click.prevent="removeDrugLanding()"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50"
                />
                <span>삭제</span>
              </b-button>
            </b-col>
          </b-row>
          <b-form-group
            rules="required"
          >
            <!-- Edit Form -->

            <b-row class="mt-1">
              <b-col
                cols="12"
                md="10"
              >
                <b-form-group
                  label="제목"
                  label-for="liveinfo-title"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="제목"
                    rules="required"
                  >
                    <b-form-input
                      id="title"
                      v-model="drugLandingData.liveInfo.title"
                      placeholder="입력하세요"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="10"
              >
                <b-form-group
                  label="제품명"
                  label-for="liveinfo-drug-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="제품명"
                    rules="required"
                  >
                    <b-form-input
                      id="liveinfo-drug-name"
                      v-model="drugLandingData.drugName"
                      placeholder="입력하세요"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="10"
                md="5"
              >
                <b-form-group
                  label="제약사"
                  label-for="druglanding-client-id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="제약사"
                    rules="required"
                  >
                    <v-select
                      id="druglanding-client-id"
                      v-model="drugLandingData.clientId"
                      :options="codes.clients"
                      placeholder="선택하세요"
                      :reduce="option => option.code"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="10"
                md="5"
              >
                <b-form-group
                  label="청구코드"
                  label-for="druglanding-search-keyword"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="청구코드"
                    rules="required"
                  >
                    <b-form-input
                      id="condDrugCodes"
                      v-model="drugLandingData.liveInfo.condDrugCodes"
                      :disabled="drugLandingData.liveInfo.liveTypeStr !== '게시 예정' && drugLandingData.liveInfoId !== null"
                      placeholder="청구코드를 입력하세요"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="10"
                md="10"
              >
                <label>게시기간</label>
              </b-col>
              <b-col
                v-if="false"
                cols="10"
                md="5"
              >
                <label>연령</label>
              </b-col>

              <b-col
                cols="10"
                md="10"
                class="d-flex align-items-center justify-content-start"
              >
                <b-form-group class="flex-grow-1">
                  <validation-provider
                    #default="{ errors }"
                    name="게시기간(Fr)"
                    :rules="{ required: true, before: drugLandingData.liveInfo.endDate }"
                  >
                    <b-form-datepicker
                      id="liveinfo-start-date"
                      v-model="drugLandingData.liveInfo.startDate"
                      placeholder="게시기간(Fr)"
                      :state="errors.length > 0 ? false : null"
                      @input="changeStartDate()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <label class="ml-50 mr-50">~</label>
                </b-form-group>

                <b-form-group class="flex-grow-1">
                  <validation-provider
                    #default="{ errors }"
                    name="게시기간(To)"
                    :rules="{ required: true, after: drugLandingData.liveInfo.startDate }"
                  >
                    <b-form-datepicker
                      id="liveinfo-end-date"
                      v-model="drugLandingData.liveInfo.endDate"
                      placeholder="게시기간(To)"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                v-if="false"
                cols="10"
                md="5"
                class="d-flex align-items-center justify-content-start"
              >
                <b-form-group class="flex-grow-1">
                  <validation-provider
                    #default="{ errors }"
                    name="시작나이"
                    rules="required"
                  >
                    <b-form-input
                      v-model="drugLandingData.liveInfo.minAge"
                      @keyup="restrictToNumbers"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <label class="ml-50 mr-50">~</label>
                </b-form-group>

                <b-form-group class="flex-grow-1">
                  <validation-provider
                    #default="{ errors }"
                    name="끝나이"
                    rules="required"
                  >
                    <b-form-input
                      v-model="drugLandingData.liveInfo.maxAge"
                      @keyup="restrictToNumbers"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="10">
                <b-form-group
                  label="진료과"
                  label-for="liveinfo-cond-dept"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="진료과"
                    rules="required"
                  >
                    <b-form-input
                      id="liveinfo-cond-dept"
                      v-model="drugLandingData.liveInfo.condDeptNames"
                      readonly
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
                class="mb-50"
              >
                <b-button
                  variant="outline-primary"
                  class="mt-0 mt-md-2"
                  @click.prevent="showMediDeptSelect = true"
                >
                  <feather-icon
                    icon="PlusSquareIcon"
                    class="mr-50"
                  />
                  <span>목록</span>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="10">
                <b-form-group
                  label="전공과"
                  label-for="liveinfo-cond-major"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="전공과"
                    rules="required"
                  >
                    <b-form-input
                      id="liveinfo-cond-major"
                      v-model="drugLandingData.liveInfo.condMajorNames"
                      readonly
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
                class="mb-50"
              >
                <b-button
                  variant="outline-primary"
                  class="mt-0 mt-md-2"
                  @click.prevent="showMajorSelect = true"
                >
                  <feather-icon
                    icon="PlusSquareIcon"
                    class="mr-50"
                  />
                  <span>목록</span>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="10">
                <b-form-group
                  label="지역"
                  label-for="liveinfo-cond-region"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="지역"
                    rules="required"
                  >
                    <b-form-input
                      id="liveinfo-cond-region"
                      v-model="drugLandingData.liveInfo.condRegionNames"
                      readonly
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
                class="mb-50"
              >
                <b-button
                  variant="outline-primary"
                  class="mt-0 mt-md-2"
                  @click.prevent="showRegionSelect = true"
                >
                  <feather-icon
                    icon="PlusSquareIcon"
                    class="mr-50"
                  />
                  <span>목록</span>
                </b-button>
              </b-col>
            </b-row>

          </b-form-group></div>
      </b-card>
    </validation-observer>

    <major-select
      :show-major-select="showMajorSelect"
      :selected-value="drugLandingData.liveInfo.condMajorCodes"
      @close="majorSelect.close"
    />

    <region-select
      :show-region-select="showRegionSelect"
      :selected-value="drugLandingData.liveInfo.condRegionCodes"
      @close="regionSelect.close"
    />

    <medi-dept-select
      :show-medi-dept-select="showMediDeptSelect"
      :selected-value="drugLandingData.liveInfo.condDeptCodes"
      @close="mediDeptSelect.close"
    />
  </div>
</template>

<script>
import axios from "@axios"
import dayjs from "dayjs"
import {
  ref, onMounted, watch, getCurrentInstance,
} from "@vue/composition-api"
import { useToast } from "vue-toastification/composition"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import vSelect from "vue-select"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { errorFormatter, arrayJoinStr } from '@core/utils/filter'
import MajorSelect from '@/views/apps/popup/MajorSelect.vue'
import RegionSelect from '@/views/apps/popup/RegionSelect.vue'
import MediDeptSelect from '@/views/apps/popup/MediDeptSelect.vue'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    MajorSelect,
    RegionSelect,
    MediDeptSelect,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
  },
  setup() {
    onMounted(() => {
      fetchCodes()
    })

    const instance = getCurrentInstance()
    const bvModal = instance.proxy.$bvModal
    const refs = instance.proxy.$refs
    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }
      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    // Main Table Config
    const drugLandingInitState = {
      liveInfoId: null,
      clientId: null,
      drugName: null,
      isUse: true,
      liveInfo: {
        title: null,
        liveInfoId: null,
        contentType: 8, // 간편처방등록
        isMoaView: true,

        // 게시 기간
        startDate: null,
        endDate: null,
        moaStartDate: null,
        moaEndDate: null,

        // 청구코드
        condDrugCodes: null,

        // 알림 노출 조건
        condDeptCodes: null,
        condDeptNames: null,
        condMajorCodes: null,
        condMajorNames: null,
        condRegionCodes: null,
        condRegionNames: null,
        minAge: 0,
        maxAge: 120,

      },
    }

    const liveInfoInitState = {
      title: null,
      liveInfoId: null,
      contentType: 8, // 간편처방등록
      isMoaView: true,

      // 게시 기간
      startDate: null,
      endDate: null,
      moaStartDate: null,
      moaEndDate: null,

      // 청구코드
      condDrugCodes: null,

      // 알림 노출 조건
      condDeptCodes: null,
      condDeptNames: null,
      condMajorCodes: null,
      condMajorNames: null,
      condRegionCodes: null,
      condRegionNames: null,
      minAge: 0,
      maxAge: 120,
    }

    const drugLandingData = ref({ ...drugLandingInitState })

    const resetDrugLandingData = () => {
      Object.assign(drugLandingData.value.liveInfo, liveInfoInitState)
      Object.assign(drugLandingData.value, drugLandingInitState)

      refs.formRules.reset()
    }

    const showMediDeptSelect = ref(false)
    const mediDeptSelect = {
      close: rs => {
        showMediDeptSelect.value = false

        if (rs) {
          drugLandingData.value.liveInfo.condDeptCodes = arrayJoinStr(rs, 'code')
          drugLandingData.value.liveInfo.condDeptNames = arrayJoinStr(rs, 'label')
        }
      },
    }

    const showMajorSelect = ref(false)
    const majorSelect = {
      close: rs => {
        showMajorSelect.value = false

        if (rs) {
          drugLandingData.value.liveInfo.condMajorCodes = arrayJoinStr(rs, 'code')
          drugLandingData.value.liveInfo.condMajorNames = arrayJoinStr(rs, 'label')
        }
      },
    }

    const showRegionSelect = ref(false)
    const regionSelect = {
      close: rs => {
        showRegionSelect.value = false

        if (rs) {
          drugLandingData.value.liveInfo.condRegionCodes = arrayJoinStr(rs, 'code')
          drugLandingData.value.liveInfo.condRegionNames = arrayJoinStr(rs, 'label')
        }
      },
    }

    // Set Codes
    const codes = ref({
      isLive: [],
      clients: [],
    })

    const fetchCodes = () => {
      axios.get("/fa/druglanding/codes")
        .then(rs => {
          const {
            postStatusType, clients,
          } = rs.data

          codes.value.postStatusType = postStatusType
          codes.value.clients = clients

          refetchData()
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }
    // Set Codes End.

    // Query Data
    const query = ref({
      liveInfoId: null,
      clientId: null,
      startDate: dayjs().add(-1, 'month').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      postStatusType: null,
      title: null,
    })

    const isProcessing = ref(false)
    const drugLandingList = ref([])
    const totalRecords = ref(0)
    const dateFormatter = value => dayjs(new Date(value)).format("YY-MM-DD")
    const showModal = ref(false)
    const currPage = ref(1)
    const tableColumns = [
      {
        key: "liveInfoId", label: "ID", sortable: true, thStyle: { width: '100px' },
      },
      {
        key: "clientName", label: "제약사명", sortable: true, thStyle: { width: '200px' },
      },
      { key: "title", label: "제목", sortable: true },
      {
        key: "liveDate", label: "게시기간", sortable: true, thStyle: { width: '240px' },
      },
      {
        key: "accessCode", label: "접속코드", sortable: true, thStyle: { width: '170px' },
      },
      {
        key: "liveTypeStr", label: "게시상태", sortable: true, thStyle: { width: '110px' },
      },
    ]

    watch([currPage], () => {
      fetchDrugLandings()
    })

    const refetchData = () => {
      fetchDrugLandings()
    }

    const onRowSelectedDrugLanding = items => {
      const item = items[0]

      if (item) {
        // resetDrugLandingData()
        fetchDrugLanding(item.liveInfoId)
      }
    }

    // Main Table Config End.
    const fetchDrugLanding = id => {
      axios.get(`/fa/druglanding/${id}`)
        .then(rs => {
          const { data } = rs

          data.title = data.liveInfo.title
          data.isUse = data.liveInfo.isUse

          drugLandingData.value = data
        })
        .catch(error => {
          console.log(error)
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    // Set Codes End.
    const fetchDrugLandings = () => {
      axios.post("/fa/druglanding/list", {
        search: {
          query: query.value,
        },
        sort: {
          predicate: 'liveInfoId',
          reverse: true,
        },
        pagination: {
          number: currPage.value,
          count: 6,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data
          drugLandingList.value = items
          totalRecords.value = totalRecord
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    const changeStartDate = () => {
      // 종료일을 시작일 +7일로 변경

      // 주어진 날짜 (년, 월, 일)
      const givenDate = new Date(drugLandingData.value.liveInfo.startDate)
      // 주어진 날짜에 일자를 더함
      givenDate.setDate(givenDate.getDate() + 6)
      // 날짜 형식 지정 (년-월-일)
      const formattedDate = `${givenDate.getFullYear()}-${(`0${givenDate.getMonth() + 1}`).slice(-2)}-${(`0${givenDate.getDate()}`).slice(-2)}`

      drugLandingData.value.liveInfo.endDate = formattedDate
    }

    // const addDate = (date, add) => {
    //   // 주어진 날짜 (년, 월, 일)
    //   const givenDate = new Date(date)

    //   // 주어진 날짜에 일자를 더함
    //   givenDate.setDate(givenDate.getDate() + add)

    //   // 날짜 형식 지정 (년-월-일)
    //   const formattedDate = `${givenDate.getFullYear()}-${(`0${givenDate.getMonth() + 1}`).slice(-2)}-${(`0${givenDate.getDate()}`).slice(-2)}`

    //   return formattedDate
    // }

    const saveDrugLanding = () => {
      // Clean Refresh 시 동작하지 않아서 임시 주석
      // const validPromise = refs.formRules.validate()

      // validPromise.then(valid => {
      //   if (!valid) return

      const {
        drugName, clientId, liveInfoId,
      } = drugLandingData.value
      const {
        title, condDrugCodes, startDate, endDate, minAge, maxAge, condDeptCodes, condMajorCodes, condRegionCodes,
      } = drugLandingData.value.liveInfo

      // 제목
      if (!title) {
        pushToast('warning', '제목을 입력하세요.')
        return
      }
      // 제품명
      if (!drugName) {
        pushToast('warning', '제품명을 입력하세요.')
        return
      }
      // 제약사
      if (!clientId) {
        pushToast('warning', '제약사를 선택하세요.')
        return
      }

      // 청구코드
      if (!condDrugCodes) {
        pushToast('warning', '청구코드를 입력하세요.')
        return
      }
      // 청구코드 : 숫자 형식인지 확인 + 9자리인지 확인
      if (!/^\d+$/.test(condDrugCodes) || condDrugCodes.length !== 9) {
        pushToast('warning', '청구코드는 9자리 숫자입니다.')
        return
      }

      // 게시기간
      if (!startDate || !endDate || startDate > endDate) {
        pushToast('warning', '게시기간을 바르게 입력하세요.')
        return
      }

      // 연령
      if (minAge > 0 || minAge > maxAge) {
        pushToast('warning', '연령을 바르게 입력하세요.')
        return
      }

      // 진료과
      if (!condDeptCodes) {
        pushToast('warning', '진료과를 선택하세요.')
        return
      }
      // 전공과
      if (!condMajorCodes) {
        pushToast('warning', '전공과를 선택하세요.')
        return
      }
      // 지역
      if (!condRegionCodes) {
        pushToast('warning', '지역을 선택하세요.')
        return
      }

      // 데이터 유효성 검사가 끝나면 저장
      if (liveInfoId) {
        updateDrugLanding()
      } else {
        saveNewDrugLanding()
      }

      // })
    }

    const saveNewDrugLanding = () => {
      if (!isProcessing.value) {
        isProcessing.value = true

        drugLandingData.value.liveInfo.moaStartDate = drugLandingData.value.liveInfo.startDate
        drugLandingData.value.liveInfo.moaEndDate = drugLandingData.value.liveInfo.endDate

        axios.put('/fa/druglanding/new', drugLandingData.value)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: `${drugLandingData.value.liveInfo.title}의 처방코드가 등록되었습니다.`,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            refetchData()

            // drugLandingData.value.liveInfoId = rs.data.id
            resetDrugLandingData()
            isProcessing.value = false
          })
          .catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: errorFormatter(error, '데이터를 저장하는 과정에서 오류가 발생하였습니다.'),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            isProcessing.value = false
          })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: errorFormatter('저장중 입니다. 잠시 기다려 주세요.'),
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }
    }

    const updateDrugLanding = () => {
      if (!isProcessing.value) {
        isProcessing.value = true

        drugLandingData.value.liveInfo.moaStartDate = drugLandingData.value.liveInfo.startDate
        drugLandingData.value.liveInfo.moaEndDate = drugLandingData.value.liveInfo.endDate

        axios.patch('/fa/druglanding/update', drugLandingData.value)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: '데이터 변경 완료',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            refetchData()

            resetDrugLandingData()
            isProcessing.value = false
          })
          .catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: errorFormatter(error, '데이터를 저장하는 과정에서 오류가 발생하였습니다.'),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            isProcessing.value = false
          })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: errorFormatter('수정중 입니다. 잠시 기다려 주세요.'),
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }
    }

    const removeDrugLanding = () => {
      if (!drugLandingData.value.liveInfoId) {
        toast({
          component: ToastificationContent,
          props: {
            title: '삭제할 대상을 선택하세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }
      bvModal
        .msgBoxConfirm('간편등록을 삭제 하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return
          axios.delete(`/fa/druglanding/${drugLandingData.value.liveInfoId}`)
            .then(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: '데이터 삭제 완료',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              refetchData()
              resetDrugLandingData()
            })
            .catch(error => {
              toast({
                component: ToastificationContent,
                props: {
                  title: errorFormatter(error, '삭제하는 과정에서 오류가 발생하였습니다.'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        })
    }

    const restrictToNumbers = () => {
      let min = String(drugLandingData.value.liveInfo.minAge)
      min = min.replace(/[^\d\s]/g, '')
      drugLandingData.value.liveInfo.minAge = min

      let max = String(drugLandingData.value.liveInfo.maxAge)
      max = max.replace(/[^\d\s]/g, '')
      drugLandingData.value.liveInfo.maxAge = max

      if (parseInt(drugLandingData.value.liveInfo.minAge, 10) > parseInt(drugLandingData.value.liveInfo.maxAge, 10)) {
        drugLandingData.value.liveInfo.minAge = drugLandingData.value.liveInfo.maxAge
      }
      if (drugLandingData.value.liveInfo.minAge > 120) { drugLandingData.value.liveInfo.minAge = 120 }
      if (drugLandingData.value.liveInfo.maxAge > 120) { drugLandingData.value.liveInfo.maxAge = 120 }
    }

    return {
      codes,
      fetchCodes,
      onRowSelectedDrugLanding,
      totalRecords,
      dateFormatter,
      tableColumns,
      refetchData,
      toast,
      resetDrugLandingData,
      fetchDrugLanding,
      fetchDrugLandings,
      query,
      drugLandingData,
      saveDrugLanding,
      updateDrugLanding,
      saveNewDrugLanding,
      drugLandingList,
      removeDrugLanding,
      showModal,
      isProcessing,
      majorSelect,
      regionSelect,
      mediDeptSelect,
      showMajorSelect,
      showRegionSelect,
      showMediDeptSelect,
      restrictToNumbers,
      changeStartDate,
      // addDate,
      currPage,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.b-form-datepicker label {
    min-width: 120px; /* datepicker 내부 텍스트 너비 고정 (날짜 선택 시마다 너비 움직이는 문제 수정) */
  }
</style>
