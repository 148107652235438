<!-- [팝업] 전공과 선택 -->
<template>
  <b-modal
    v-model="showMajorSelect"
    cancel-variant="outline-secondary"
    centered
    ok-title="적용"
    cancel-title="취소"
    size="lg"
    title="전공과 선택"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="submit()"
    @close.prevent="close()"
    @cancel.prevent="close()"
  >
    <b-row
      class="mt-25"
      style="border-bottom: 1px solid #ebe9f1;"
    >
      <b-col
        cols="12"
      >
        <b-form-checkbox
          v-model="selectAll"
          class="mb-1"
          style="width: 120px"
          @change="changeSelectAll()"
        >
          전체 전공
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col
        cols="12"
      >
        <b-form-checkbox-group
          v-model="selects"
          value-field="code"
          text-field="label"
          :disabled="selectAll"
        >
          <b-form-checkbox
            v-for="dept in codes.hcpMajor"
            :key="dept.code"
            :value="dept.code"
            class="mb-25 mr-3"
            style="width: 120px"
          >
            {{ dept.label }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import axios from '@axios'
import { ref, watch, onMounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
  },

  props: {
    showMajorSelect: {
      type: Boolean,
      required: true,
    },
    selectedValue: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup(props, { emit }) {
    onMounted(() => {
      fetchCodes()
    })

    watch(
      () => props.showMajorSelect,
      newVal => {
        if (newVal) {
          selects.value = []
          selectAll.value = false

          if (props.selectedValue && props.selectedValue.length > 0) {
            if (props.selectedValue.toString() === '0') selectAll.value = true
            else selects.value = props.selectedValue.split(',').map(Number)
          }
        }
      },
    )

    const toast = useToast()

    const selects = ref([])
    const selectAll = ref(false)

    // Set Codes
    const codes = ref({
      hcpMajor: [],
    })

    const fetchCodes = () => {
      axios.get('/fa/major-select/codes')
        .then(rs => {
          const {
            hcpMajor,
          } = rs.data
          codes.value.hcpMajor = hcpMajor
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '코드 데이터를 불러오는데 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    // Set Codes End.

    const changeSelectAll = () => {
      if (selectAll.value) {
        selects.value = []
      }
    }

    const close = () => {
      emit('close')
    }

    const submit = () => {
      if (!selectAll.value && selects.value.length === 0) {
        toast({
          component: ToastificationContent,
          props: {
            title: '전공을 한 개 이상 선택하세요',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      const retVal = []
      if (selectAll.value) {
        retVal.push({ code: '0', label: '전체 전공과' })
      } else {
        const filts = codes.value.hcpMajor.filter(el => selects.value.indexOf(el.code) > -1)
        if (filts.length) {
          filts.forEach(el => {
            retVal.push(el)
          })
        }
      }
      emit('close', retVal)
    }

    return {
      codes,
      close,
      submit,
      selects,
      selectAll,
      changeSelectAll,
    }
  },
}
</script>
